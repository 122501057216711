import React from "react";

export default function NotFound () {
    return (
        <>
            <div id="wrapper" className='notfound'>
                <img  width='2000' src={require('../images/not_found.jpeg')} />
            </div >
        </>
    );
}
